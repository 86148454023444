@media (max-width: 1000000px) {
  .offcanvas-collapse {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    /* Height of navbar */
    bottom: 0;
    width: 100%;
    // padding-right: 1rem;
    // padding-left: 1rem;
    background-color: $colorBlackTransparency;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    &.open {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      /* Account for horizontal padding on navbar */
    }
    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

    }
  }
}

$colorDots: #231f20;

.auto-hiding-header {

  @include media-breakpoint-down(md) {

    transform: translateZ(0);
    will-change: transform;
    transition: all .3s;

    &.is-hidden {
      transform: translateY(-131px);
      @include media-breakpoint-up(md) {
        transform: translateY(-80px);
      }
      .navbar-brand-holder {
        max-height: 60px;
        will-change: transform;
        transition: all .3s;
      }
      .navbar-brand-holder-inner .brand .logo img {
        left: 10px;
        top: 5px;
        transform: translateX(0);
        will-change: transform;
        transition: all .3s;
        max-height: 45px;
      }
      .navbar-brand-holder-inner .navbar-toggle {
        opacity: 1.0;
        transition: all .3s;
      }
    }

  }

}

.navbar {
  background-color: $colorFireBush;
  padding: 0;

  &-toggler {

    margin-right: 10px;

    &-icon {
      background-image: url(../../images/hamburger.svg);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;

      &.close {
        background-size: contain;
        float: none;
        opacity: 1;
        background-image: url(../../images/close.svg);
        max-height: 24px;
      }
    }
  }

  &-header {
    width: 100%;

    &-content {
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;

      .offers {
        background-color: white;
        color: $colorFireBush;
        &:hover {
          color: $colorNavy;
        }
      }

      .social-icons {

        padding-left: 0px;

        @include media-breakpoint-up(sm) {
          padding-left: 10px;
          padding-right: 9px;
        }
        @include media-breakpoint-up(xl) {
          padding-left: 0px;
          padding-right: 0px;
        }

        .socialIcon {
          width: 35px;
          height: 35px;
          margin-right: 0px;
          margin-left: 12px;
          @include float-left;
          @include media-breakpoint-up(sm) {
            width: 28px;
            height: 28px;
            margin-left: 10px;
          }
          @include media-breakpoint-up(xl) {
            margin-left: 0px;
            margin-right: 12px;
          }
          svg {
            fill: white;
            &:hover {
              fill: $colorPuertoRico;
            }
          }

        }

      }

      .header-links {
        .btn {
          color: white;
          text-transform: uppercase;
          padding: 5px 8px;
          border-radius: 2px;
          padding: 5px 15px;
          font-size: 12px;
          @include media-breakpoint-down(lg) {
            font-size: 11px;
          }
          &.offers {
            color: $colorFireBush;
            &:hover {
              color: $colorNavy;
            }
          }
        }
      }

      .header-booking {
        //margin-right: 15px;

      }

    }

    &-booking {

      padding: 0px 15px 15px 15px;

    }

    .form-booking-holder {

      .date {
        padding-right: 0px;
        &-arrive {

        }
        &-depart {
        }

      }

      input[type=text] {
        height: 35px;
        padding: 5px;
        text-align: center;
        background-color: $colorFireBush;
        border: 1px solid white;
        border-radius: 2px;
        color: white;
        font-family: $fontMontserrat;
        font-weight: $fontWeightUltraLt;
        font-size: 12px;
        letter-spacing: 2px;

        &::placeholder {
          color: white;
        }

        @media (max-width: 320px) {
          font-size: 10px;
        }

        &.parsley-error {
          background-color: red;
        }

      }

      button.ui-datepicker-trigger {
        background-color: transparent;
        border: 0px;
        padding: 0px 0px 0px 0px;
        width: 22px;
        height: 22px;
        margin: 7px;
        cursor: pointer;
        img {
          margin-top: -2px;
          width: 100%;
          height: 100%;
        }
      }

      .submit {
        padding-left: 0px;
        @include media-breakpoint-up(sm) {
          padding-left: 10px;
        }
      }

      .submit-booking-hero {
        position: relative;
        z-index:0;
        height: 35px;
        padding: 5px 15px;
        background-color: $colorBright;
        border: 1px solid white;
        border-radius: 2px;
        color: white;
        font-family: $fontMontserrat;
        font-weight: $fontWeightUltraLt;
        font-size: 12px;
        letter-spacing: 2px;

        &:before {
          content: "";
          position: absolute;
          z-index:-1;
          top: 0;  left: 0;  right: 0; bottom: 0;
          background: white;
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition: transform .3s ease-out;

        }
        &:hover {
          color: $colorBright;
          &:before {
            transform: scaleX(1);
          }
        }

        &:after {
          content: "BOOK NOW";
          @media (max-width: 375px) {
            content: "BOOK";
          }
        }

        @media (max-width: 320px) {
          font-size: 10px;
        }

      }

      ul.parsley-errors-list {
        display: none;
      }

    }

  }

  &-brand {

    &-holder {
      background-color: white;
      border-top: 2px solid $colorCinnabar;
      border-bottom: 2px solid $colorCinnabar;
      width: 100%;
      height: 100%;
      height: 94px;
      //display: flex;
      //flex-direction: column;
      justify-content: center;

      &:before {
        content: '';
        display: block;
        width: 100%;
        border-top: 1px solid transparent;
        border-bottom: 1px dotted $colorDots;
        box-shadow: 0 0 0 0 $colorCinnabar;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        //border-bottom: 1px solid white;
        border-top: 1px dotted $colorDots;
        box-shadow: 0 0 0 0 $colorCinnabar;
      }

      &-inner {
        //height: 90px;
        height: 100%;
        margin-bottom: -4px;
        position: relative;

        .brand {
          //text-align: center;

          @include media-breakpoint-up(lg) {
            text-align: left;
          }

          .row {
            margin: 0px;
            @include media-breakpoint-up(lg) {
              height: 82px;
            }
          }

          .logo {
            padding-left: 0px;
            padding-right: 0px;
            @include media-breakpoint-up(lg) {
              padding-left: 15px;
              padding-right: 15px;
            }
            @include media-breakpoint-up(xl) {
              padding-left: 0px;
              padding-top: 0px;
              padding-right: 20px;
            }

            img {
              height: 64px;
              width: 100%;
              position: absolute;
              top: 10px;
              left: 50%;
              transform: translateX(-50%);
              @include media-breakpoint-up(lg) {
                position: relative;
              }
            }

          }

          .navbar-toggle {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 35px;
            height: auto;
            opacity: 0;

          }

          .menu {
            padding-left: 0px;
            padding-right: 0px;

            .navbar {
              background-color: transparent !important;
              width: 100%;
              height: 90px;
              margin-top: -2px;
              @include float-right;

              .navbar-nav {
                flex-direction: row;

                .nav-item {

                  a {
                    color: $colorBright;
                    font-family: $fontBalooDa;
                    font-size: 18px;
                    text-transform: uppercase;

                    &:focus{
                      outline: none;
                    }

                    @include media-breakpoint-down(lg) {
                      font-size: 16px;
                    }

                    &.nav-link {
                      line-height: 70px;
                    }

                    &.dropdown-toggle::after {
                      border: 0px;
                    }
                  }

                  &.home { //the menu item's classNames property in the CMS must be set to home
                    display: none;
                  }

                  &.dropdown {
                    &.show {
                      .nav-link {
                        background-color: $colorBright;
                        color: white;
                      }
                    }
                    &:hover, &.active {
                      .nav-link {
                        background-color: $colorBright;
                        color: white;
                      }
                    }
                  }

                  .dropdown-menu {
                    position: absolute;
                    margin-top: -1px;
                    padding: 0px;
                    border-radius: 0px;
                    border: 0px;
                    -webkit-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
                    -moz-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
                    box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);

                    &:before {
                      content: '';
                      display: block;
                      width: 100%;
                      border-top: 1px solid transparent;
                      border-bottom: 1px dotted $colorDots;
                      box-shadow: 0 0 0 0 $colorCinnabar;
                    }

                    .dropdown-item {
                      padding-left: 24px;
                      line-height: 50px;
                      &:first-child {
                        border-top: 2px solid $colorCinnabar;
                        margin-top: 1px;
                      }
                      &:hover, &.active {
                          background-color: $colorBright;
                          color: white;
                      }
                    }

                  }

                  .dropdown-menu-right {
                      right: 0;
                      left: auto;
                  }

                }
              }

            }

          }

        }

      }

    }

  }

  &-collapse {
    padding: 0;

    .nav-container {

      overflow-y: auto;

      margin-left: 15vw;
      height: 100%;

      background-color: $colorSilver;

      .navbar-nav {
        display: block;
        width: 100%;
      }

      ul {

        padding: 0px;

        & > li {

          &.nav-item {
            z-index: 100;
            background-color: white;
            min-height: 50px;
            font-family: $fontBalooDa;
            font-size: 24px;
            line-height: 50px;
            border-bottom: 1px solid #f0f0f0;

            &.closer {
              button {
                text-transform: uppercase;
                margin-left: 25px;
                padding: 0px;
                color: #707070;
              }
            }

            .nav-link {
              padding: 0px;
              color: $colorFireBush;
              padding-left: 25px;
            }

            &.dropdown {

              .dropdown-toggle {
                margin-right: 50px;

                &:after {
                  display: none;
                }

              }

              .caret {
                position: absolute;
                top: 0px;
                right: 0px;
                border: 1px solid #e1e1e1;
                border-radius: 15px;
                width: 30px;
                height: 30px;
                margin-top: 10px;
                margin-right: 20px;
                background-image: url(/images/menu-open.png);
                background-repeat: no-repeat;
                background-position: center;
              }

              &.show {
                .caret {
                  background-image: url(/images/menu-close.png);
                }
                .dropdown-menu {
                  //display: block;
                  max-height: 1000px;
                }
              }

              .dropdown-menu {
                z-index: 99;
                border: 0px;
                margin: 0px;
                padding: 0px;

                display: block;
                max-height: 0;
                overflow: hidden;

                transition: max-height 0.3s;

                .dropdown-item {
                  margin: 0px;
                  padding: 0px;
                  padding-left: 25px;
                  border-top: 1px solid #f0f0f0;
                  font-size: 20px;
                  color: #707070;
                  &:visited {
                    color: #707070;
                  }
                  &.active {
                    background-color: $colorSilver;
                  }
                }

              }

            }

          }

        }

      }

      .nav-footer {
        position: relative;

        .nav-bg {
          position: absolute;
          z-index: 90;
          width: 100%;
          height: 100%;
          background-image: url(/images/palm-tree-silver.svg);
          background-repeat: no-repeat;
          transform: translate(-12%, 10%) rotate(15deg);
          @include media-breakpoint-up(sm) {
            width: 200%;
            height: 200%;
            transform: translate(-9%, 20%) rotate(15deg);
          }
        }

        .nav-links {
          position: relative;
          z-index: 100;
          display: block;
          padding-top: 20px;

          a.link {
            display: block;
            text-align: center;
            padding: 15px;
            text-transform: uppercase;
            font-family: $fontMontserrat;
            font-size: 12px;
            color: #444444;
          }

        }

        .social-icons {
          display: inline-block;
          position: relative;
          z-index: 100;
          width: 100%;
          padding-top: 20px;
          padding-bottom: 20px;
          text-align: center;

          .socialIcon {
            display: inline-block;
            width: 35px;
            height: 35px;
            margin-right: 6px;
            margin-left: 6px;
            svg {
              fill: $colorPuertoRico;
              &:hover {
                fill: white;
              }
            }
          }
        }
      }

    }
  }

}
