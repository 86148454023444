body {
  position: relative;
  z-index: 1;
}

h1, h2, h3, h4, h5, h6 {
  @include media-breakpoint-up(sm) {
    letter-spacing: 1px;
  }
}

.sticky-top {
  z-index: 900;
}

.btn {
  border-radius: 2px;
}

ul.parsley-errors-list {
  list-style: none;
  padding: 10px 0px;
  margin: 0;
  li {
    display: block;
    color: red;
  }
}

.ui-datepicker {
  z-index: 1000 !important;
}

* {
  outline: none !important;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

form {
  label {
    display: none;
  }
  input.form-control {
    border: 0px;
    border-bottom: 1px solid $colorTundora;
    border-radius: 0px;
    color: $colorTundora;
    background-color: transparent;
    padding-left: 0px;
  }
  .datepicker {
    position: relative;
    input {

    }
    button.ui-datepicker-trigger {
      position: absolute;
      right: 10px;
      top: 0;
      background-color: transparent;
      border: 0px;
      padding: 0px 0px 0px 0px;
      width: 22px;
      height: 22px;
      margin: 7px;
      cursor: pointer;
      img {
        margin-top: -2px;
        width: 100%;
        height: 100%;
      }
    }
  }
  button[type="submit"] {
    position: relative;
    z-index:0;
    text-transform: uppercase;
    color: $colorPuertoRico;
    background-color: white;
    border: 1px solid $colorPuertoRico;
    letter-spacing: 2px;
    &:before {
      content: "";
      position: absolute;
      z-index:-1;
      top: 0;  left: 0;  right: 0; bottom: 0;
      background: $colorPuertoRico;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform .3s ease-out;

    }
    &:hover {
      color: white;
      &:before {
        transform: scaleX(1);
      }
    }
  }
}

.light-orange {
  color: $colorFireBush;
}

.dark-orange {
  color: $colorCinnabar;
}

.teal {
  color: $colorPuertoRico;
}
