// Bootstrap
// =========

// Bootstrap can be removed entirely by deleting this line.

@import "bootstrap/bootstrap";

// The easiest way to customise Bootstrap variables while
// being able to easily override the source files with new
// versions is to override the ones you want in another file.
//
// You can also add your own custom variables to this file for
// use in your site stylesheets.

@import "site/variables";
@import "site/typography";
@import "site/elements";
@import "site/card";
@import "site/callout";

// Site Styles
// ===========

// Add your own site style includes here
@import "site/layout";
@import "site/hero";
@import "site/footer";
@import "site/navigation";
@import "site/content";

@import "../slick/slick.scss";
