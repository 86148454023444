//Colors
$colorCinnabar:                          #e85434;
$colorBright: #ff571f;
$colorPuertoRico:                       #42beb2;
$colorFireBush:                          #ec9132;
$colorSilver:                               #f7f7f7;
$colorTundora:                       #444444;
$colorStone: #9e9e93;
$colorSeashell:                       #f1f1f1;
$colorBlackTransparency:              rgba(black, 0.5);
$colorNavy: #0c4d9f;

$colorBase:                       $colorTundora;
$colorHighlight:               $colorPuertoRico;

$font-size-base: 16px;


//Fonts
$fontBalooDa:                         'Baloo Da', cursive;
$fontMontserrat:                      'Montserrat', sans-serif;

$font-family-sans-serif:                         $fontMontserrat;
$font-family-serif:                                 $fontBalooDa;

$fontBody:                                   $font-family-sans-serif;
$fontHeadings:                             $font-family-serif;

$fontWeightUltraLt:                   200;
$fontWeightLt:                          300;
$fontWeightSemiBold:              600;
