.container {

  @include media-breakpoint-down(sm) {

  }

  @include media-breakpoint-down(md) {

  }

  @include media-breakpoint-down(lg) {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    width: 100%;
  }

  &.content {
    padding-left: 0px;
    padding-right: 0px;
  }

}

.sticky {
  //position: -webkit-sticky;
  position: fixed;
  top: 0;
  width: 100%;
}

.bm-ebs-button { 
  position: relative;
  z-index: 0;
  text-transform: uppercase;
  padding: 5px 15px;
  color: $colorPuertoRico;
  background-color: white;
  border: 1px solid $colorPuertoRico;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 15px;
  &:before {
    content: "";
    position: absolute;
    z-index:-1;
    top: 0;  left: 0;  right: 0; bottom: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform .3s ease-out;
    background: $colorPuertoRico;
  }
  &:hover {
    color: white;
    &:before {
      transform: scaleX(1);
    }
  }
}
