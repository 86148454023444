/* Homepage Template */
.homepage-template {

  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }

    .tree1 {
      display: none;
      z-index: 300;
      @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        top: 574px;
        right: 0px;
        transform: scaleX(-1) translateX(-100px) rotate(15deg);
        height: 500px;
        width: 486px;
        fill: #fdfdfd;
      }
      @include media-breakpoint-up(lg) {
        top: 674px;
      }
      @include media-breakpoint-up(xl) {
        height: 500px;
        top: 754px;
        transform: scaleX(-1) translateX(-170px) rotate(15deg);
      }
    }

    .tree2 {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        top: 1474px;
        left: 0px;
        transform: translateX(-35%);
        height: 350px;
        width: 259px;
        fill: $colorSilver;
      }
    }

    .tree3 {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        z-index: 800;
        bottom: -100px;
        right: -40px;
        transform: scaleX(-1) translateX(-50px) rotate(15deg);
        height: 350px;
        width: 340.5px;
        fill: #ececec;
      }
    }

    .hero-booking {
      display: none;
      -webkit-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
      z-index: 400;
      text-align: center;
      color: $colorCinnabar;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 30px;
        top: 450px;
        width: 350px;
        height: 310px;
      }
      @include media-breakpoint-up(lg) {
        top: 554px;
        right: 50px;
      }
      .back-box {
        background-color: $colorPuertoRico;
        position: absolute;
        right: -5px;
        top: -5px;
        width: 90%;
        height: 90%;
        z-index: 401;
      }
      .inner {
        padding: 30px;
        background-color: white;
        z-index: 402;
      }
      .dates {
        display: flex;
        flex: 2;
        flex-direction: row;
        height: 145px;

        .date {
          position: relative;
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          border: 1px solid $colorCinnabar;
          padding: 15px;
          cursor: pointer;
          &.arrival {
            margin-right: 15px;
          }
          &.departure {
            margin-left: 15px;
          }
          .label {
            font-size: 14px;
            margin-top: -24px;
            background-color: white;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 10px;
          }
          .month {
            font-weight: bold;
            flex: 1 0 0;
          }
          .day {
            flex: 2;
            font-size: 40px;
            font-weight: 100;
          }
          .year {
            flex: 1 0 0;
          }
          .ui-datepicker-inline {
            position: absolute;
            bottom: 0px;
            right: 0px;
          }
        }
      }
      .guests {
        position: relative;
        flex: 1 0 0;
        text-align: center;
        line-height: 67px;
        font-size: 30px;
        font-weight: 100;
        margin: 5px 0;
        img {
          cursor: pointer;
        }
        .up {
          position: absolute;
          width: 15px;
          height: 15px;
          top: 3px;
          left: 50%;
          margin-left: -7px;
        }
        .down {
          position: absolute;
          width: 15px;
          height: 15px;
          bottom: 3px;
          left: 50%;
          margin-left: -7px;
          transform: scaleY(-1);
        }
      }
    }

    .content-holder {
      background-image: url(../../images/homepage-content-mobile.png);
      background-repeat: no-repeat;
      background-size: cover;

      color: white;

      padding-top: 5px;
      padding-bottom: 5px;

      &:before, &:after {
        content: '';
        width: 100%;
        height: 5px;
        display: block;
        background-image: url(../../images/ruler.svg);
        background-position: 0%;
      }

      &:after {
        background-position: 100%;
      }

      .inner {
        padding: 50px 45px;
        @include media-breakpoint-up(md) {
          margin-right: 350px;
        }
        @include media-breakpoint-up(lg) {
          max-width: 600px;
        }
      }

    }

    .offers-holder {
      border-left: 10px solid white;
      border-right: 10px solid white;
      border-bottom: 10px solid white;
      padding-bottom: 30px;

    }

  }

}

/* Content Template */

.content-template {

  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }

    .tree1 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        z-index: 800;
        bottom: -100px;
        right: 0px;
        transform: scaleX(-1) translateX(-50px) rotate(15deg);
        height: 350px;
        width: 340px;
        fill: #ececec;
      }
    }

    .content-holder {
      position: relative;
      z-index: 400;
      margin-top: -75px;
      margin-left: 0px;
      margin-right: 0px;

      .content-mask {
        position: absolute;
        top: 0;
        height: 100px;
        width: 100%;
        background-image: url(/images/content-swoop-mask.svg);
        background-repeat: no-repeat;
      }

      .content-column {
        padding-top: 120px;
        padding-bottom: 250px;

        h1 {
          color: $colorFireBush;
        }

        h2 {
          font-family: $fontMontserrat;
          font-weight: 100;
          color: $colorPuertoRico;
        }

        h3 {
          color: $colorPuertoRico;
        }

        .wysiwyg {
          img {
            outline: 1px solid white !important;
            outline-offset: -10px;
            width: 100%;
            height: auto;
            @include media-breakpoint-up(sm) {
              margin-left: 10px;
              width: auto;
              height: auto;
            }
          }
        }

        ul {
          list-style: none;
          padding-left: 0px;
          li::before {
            content: "\2022";
            /* Unicode of character to precede the list item */

            font-size: 1.5em;
            /* em or %*/

            padding-right: .5em;
            /* use em*/

            position: relative;
            top: 0em;
            color: $colorPuertoRico;
          }
        }

      }
      .content-sidebar {
        padding-top: 30px;
        @include media-breakpoint-up(lg) {
          padding-top: 120px;
        }
        .offers-holder {
          padding: 0px;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 20px;

          .slick-prev {
            left: -27px;
          }
          .slick-next {
            right: -27px;
          }
          .slick-dots {
            margin-top: -50px;
          }

          @include media-breakpoint-up(md) {
          }

          @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 30px;
            padding-bottom: 10px;
          }

          .offers-inner {
            .offer {
              padding-bottom: 40px;
            }
          }

        }

        .instagram-holder {
          margin-left: 40px;
          margin-right: 40px;
          text-align: center;
          .socialIcon {
            width: 30px;
            height: 30px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
            svg {
              fill: white;
            }
          }
          a {
            color: white;
            font-family: $fontBalooDa;
            font-weight: 100;
          }
          .instagram-inner {
            margin-top: 10px;
            padding: 20px;
            background-color: white;
            .lightwidget-widget {
              width:100%;
              border:0;
              overflow:hidden;
            }
          }
        }

      }
    }
  }
}

/* Title Template */

.title-template {

  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }

    .tree1 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        top: 554px;
        left: 0px;
        transform: translateX(-35%);
        height: 600px;
        width: 443.5px;
        fill: $colorSilver;
        z-index: 600;
      }
    }

    .tree2 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        z-index: 800;
        bottom: -100px;
        right: -40px;
        transform: scaleX(-1) translateX(-50px) rotate(15deg);
        height: 350px;
        width: 340px;
        fill: #ececec;
      }
    }
  }

  .content-holder {
    text-align: center;
    padding: 20px;

    @include media-breakpoint-up(lg) {
      padding: 20px 250px;
    }

    h1 {
      color: $colorFireBush;
    }

    h2 {
      font-family: $fontMontserrat;
      font-weight: 100;
      color: $colorPuertoRico;
      font-size: 20px;
    }

    h3 {
      color: $colorPuertoRico;
    }

    img {
      margin-left: 10px;
      outline: 1px solid white !important;
      outline-offset: -10px;
    }

    ul {
      list-style: none;
      padding-left: 0px;
      li::before {
        content: "\2022";
        /* Unicode of character to precede the list item */

        font-size: 1.5em;
        /* em or %*/

        padding-right: .5em;
        /* use em*/

        position: relative;
        top: 0em;
        color: $colorPuertoRico;
      }
    }

  }

  .callout-holder {
    .callout {
      &:nth-child(4n + 1) {
        background-color: $colorCinnabar;
        h3 {
          color: white;
        }
        .callout-content {
          color: white;
          .button-callout {
            color: white;
            border-color: white;
            &:before {
              background: white;
            }
            &:hover {
              color: $colorCinnabar;
            }
          }
        }

      }

      &:nth-child(4n + 2) {
        background-color: $colorPuertoRico;
        .callout-content{
          .button-callout {
            &:before {
              background: white;
            }
            &:hover {
              color: $colorPuertoRico;
            }
          }
        }
      }

      &:nth-child(4n + 3) {
        background-color: $colorSeashell;
        h3 {
          color: $colorFireBush;
        }
        .callout-content {
          color: black;
          .button-callout {
            color: $colorPuertoRico;
            border-color: $colorPuertoRico;
            &:before {
              background: $colorPuertoRico;
            }
            &:hover {
              color: white;
            }
          }
        }

      }

      &:nth-child(4n + 4) {
        background-color: $colorFireBush;
        color: white;
        .callout-content {
          .button-callout {
            &:before {
              background: white;
            }
            &:hover {
              color: $colorFireBush;
            }
          }
        }

      }
    }
  }

  .offers-holder {

    border-left: 10px solid white;
    border-right: 10px solid white;
    border-bottom: 10px solid white;

    padding-bottom: 30px;
  }

}

/* Rooms Template */

.rooms-template {
  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }

    .tree1 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        z-index: 800;
        top: 100px;
        right: -40px;
        transform: scaleX(-1) translateX(-50px) rotate(15deg);
        height: 350px;
        width: 340.5px;
        fill: #ececec;
      }
    }

    .content-holder {
      text-align: center;
      background-color: $colorPuertoRico;
      color: white;
      padding-bottom: 5px;
      .inner {
        padding: 30px;
        @include media-breakpoint-up(lg) {
          padding: 30px 300px;
        }
      }
      &:after {
        content: '';
        width: 100%;
        height: 5px;
        display: block;
        background-image: url(../../images/ruler.svg);
        background-position: 50%;
      }
    }
    .rooms-holder {
      padding: 20px;
      .room {
        margin-bottom: 20px;
        -webkit-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);

        @include media-breakpoint-up(lg) {
          margin-bottom: 30px;
        }

        .row {
          margin-left: 0px;
          margin-right: 0px;

          @include media-breakpoint-up(md) {
            border-bottom: 1px solid #e5e5e5;
          }

          .gallery {
            width: 100%;
            height: 160px;
            padding: 0px;
            background-color: black;
            overflow: hidden;
            position: relative;
            z-index: 500;

            @include media-breakpoint-up(md) {
              height: 320px;
            }

            .slick-arrow {
              position: absolute;
              top: 65px;
              width: 18px;
              height: 30px;
              line-height: 0;
              font-size: 0;
              background-color: transparent;
              background-image: url(/images/arrow.svg);
              background-repeat: no-repeat;
              background-position: center;
              border: 0px;
              cursor: pointer;
              z-index: 501;
              @include media-breakpoint-up(md) {
                top: 135px;
              }
            }
            .slick-prev {
              left: 10px;
            }
            .slick-next {
              right: 10px;
              transform: scaleX(-1);
            }
            .slick-dots {
              margin-top: -60px;
              margin-left: 9.5px;
              margin-right: 9.5px;
              padding: 0px;
              list-style: none;
              text-align: center;
              li {
                position: relative;
                display: inline-block;
                padding: 0;
                button {
                  margin-left: 5px;
                  margin-right: 5px;
                  background-color: transparent;
                  border: 0px;
                  cursor: pointer;
                  font-size: 0;
                  padding: 0;
                  width: 27px;
                  height: 27px;
                  background-image: url(/images/gallery-dot.svg);
                  background-repeat: no-repeat;
                }
                &.slick-active {
                  button {
                    background-image: url(/images/gallery-dot-selected.svg);
                  }
                }
              }
            }
            .room-image {
              width: 100%;
              height: 160px;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              @include media-breakpoint-up(md) {
                height: 320px;
              }
            }
          }
          .info {
            display: flex;
            flex-direction: column;
            padding: 20px;
            @include media-breakpoint-up(lg) {
              padding: 40px;
            }
            h3 {
              flex: 1 0 0;
              text-align: center;
              color: $colorPuertoRico;
            }
            h4 {
              flex: 1 0 0;
              text-align: center;
              color: $colorPuertoRico;
              font-family: $fontMontserrat;
              font-weight: 100;
              font-size: 20px;
            }
            .descr {
              flex: 1 0 0;
              font-size: 14px;
            }
            .booking {
              flex: 1 0 0;
            }
          }
        }
        .features {
          display: flex;
          flex-wrap: wrap;
          padding: 0px 20px;

          @include media-breakpoint-up(lg) {
            padding: 20px 100px;
          }

          .feat {
            flex: 0 0 100%;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 20px;
            width: 50%;
            min-height: 30px;
            margin-bottom: 10px;
            padding-left: 32px;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: 5px 0px;

            @include media-breakpoint-up(sm) {
              flex: 0 0 43%;
            }
            @include media-breakpoint-up(md) {
              flex: 0 0 28%;
            }
          }
          & .sertaMattress {
            background-image: url(/images/room-feat-serta.svg);
          }
          & .threadCount {
            background-image: url(/images/room-feat-linens.svg);
          }
          & .safe {
            background-image: url(/images/room-feat-safe.svg);
          }
          & .hairDryer {
            background-image: url(/images/room-feat-dryer.svg);
          }
          & .tv {
            background-image: url(/images/room-feat-tv.svg);
          }
          & .iron {
            background-image: url(/images/room-feat-iron.svg);
          }
          & .internet {
            background-image: url(/images/room-feat-internet.svg);
          }
          & .artwork {
            background-image: url(/images/room-feat-art.svg);
          }
          & .sq-ft {
            background-image: url(/images/room-feat-sqft.svg);
          }
          & .stripView {
            background-image: url(/images/room-feat-view.svg);
          }
          & .beds {
            background-image: url(/images/room-feat-bed.svg);
          }
          & .stripView {
            background-image: url(/images/room-feat-view.svg);
          }
          & .poolView {
            background-image: url(/images/room-feat-view.svg);
          }
          & .livingRoom {
            background-image: url(/images/room-feat-fridge.svg);
          }
          & .pullOut {
            background-image: url(/images/room-feat-couch.svg);
          }
          & .bathrooms {
            background-image: url(/images/room-feat-bathroom.svg);
          }
          & .jacuzziTub {
            background-image: url(/images/room-feat-jacuzzi.svg);
          }
          & .kingBed {
            background-image: url(/images/room-feat-bed.svg);
          }
          & .parking {
            background-image: url(/images/room-feat-parking.svg);
          }
        }
        .booking {
          padding: 20px;
          @include media-breakpoint-up(md) {
            padding: 0px;
          }
          a.btn {
            width: 200px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            text-transform: uppercase;
            letter-spacing: 2px;
            background-color: $colorBright;
            color: white;
          }
          .call {
            text-align: center;
            font-family: $fontBalooDa;
            text-transform: uppercase;
            padding: 20px 40px;
            @include media-breakpoint-up(md) {
              padding: 0px;
            }
            a {
              color: $colorBright;
            }
          }
        }
      }
    }
    .offers-holder {

      border-left: 10px solid white;
      border-right: 10px solid white;
      border-bottom: 10px solid white;

      padding-bottom: 30px;
    }
  }

}

/* Offers Template */
.offers-template {
  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }

    .tree1 {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        z-index: 800;
        top: 100px;
        right: -40px;
        transform: scaleX(-1) translateX(-50px) rotate(15deg);
        height: 350px;
        width: 340.5px;
        fill: #ececec;
      }
    }

    .content-holder {
      text-align: center;
      background-color: $colorPuertoRico;
      color: white;
      padding-bottom: 5px;
      .inner {
        padding: 30px;
        @include media-breakpoint-up(lg) {
          padding: 30px 300px;
        }
      }
      &:after {
        content: '';
        width: 100%;
        height: 5px;
        display: block;
        background-image: url(../../images/ruler.svg);
        background-position: 50%;
      }
    }
    .offers-listing-holder {
      padding: 30px;

      .offers-listing-inner {
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        @include media-breakpoint-up(sm) {
          margin: -10px;
        }

        .offer {
          flex: 1 0 100%;
          margin: 0 0 30px 0;
          -webkit-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
          -moz-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
          box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);

          @include media-breakpoint-up(sm) {
            flex: 0 0 46.9%;
            margin: 10px auto;
          }
          @include media-breakpoint-up(lg) {
            flex: 0 0 31%;
            margin: 10px;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              flex: 0 0 29%;
            }
          }
        }
      }

      nav {
        margin-top: 30px;
        background-color: white;

        .page-item {
          &.active {
            .page-link {
              background-color: transparent;
              color: black;
            }
          }
          &:nth-child(2) {
            margin-left: 20px;
          }
          &:nth-last-child(2) {
            margin-right: 20px;
          }
          .page-link {
            cursor: pointer;
            border: 0px;
            border-radius: 0px;
            color: #c5c5c5;
            padding: 10px 15px;
            &.back {
              padding: 10px;
              color: transparent;
            }
            &.next {
              padding: 10px;
              color: transparent;
              img {
                transform: scaleX(-1);
              }
            }
            img {
              margin-top: -2px;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

/* Offer Detail Template */
.offer-template {

  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }
  }

  .container {
    position: relative;

    .content-holder {
      position: relative;
      z-index: 400;
      margin-top: -75px;
      margin-left: 0px;
      margin-right: 0px;

      .content-mask {
        position: absolute;
        top: 0;
        height: 100px;
        width: 100%;
        background-image: url(/images/content-swoop-mask.svg);
        background-repeat: no-repeat;
      }

      .content-column {
        padding-top: 120px;

        h1 {
          color: $colorFireBush;
          margin-bottom: 0px;
        }

        h2 {
          color: $colorPuertoRico;
          font-family: $fontMontserrat;
          font-size: 20px;
          font-weight: 100;
        }

        .btn {
          position: relative;
          z-index: 0;
          text-transform: uppercase;
          color: $colorPuertoRico;
          background-color: white;
          border: 1px solid $colorPuertoRico;
          letter-spacing: 2px;
          margin-bottom: 10px;
          &:before {
            content: "";
            position: absolute;
            z-index:-1;
            top: 0;  left: 0;  right: 0; bottom: 0;
            transform: scaleX(0);
            transform-origin: 0 50%;
            transition: transform .3s ease-out;
            background: $colorPuertoRico;
          }
          &:hover {
            color: white;
            &:before {
              transform: scaleX(1);
            }
          }
        }

      }

      .content-sidebar {
        padding-top: 30px;
        @include media-breakpoint-up(lg) {
          padding-top: 120px;
        }
        .offers-holder {
          padding: 0px;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 20px;

          .slick-prev {
            left: -27px;
          }
          .slick-next {
            right: -27px;
          }
          .slick-dots {
            margin-top: -50px;
          }

          @include media-breakpoint-up(md) {
          }

          @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 30px;
            padding-bottom: 10px;
          }

          .offers-inner {
            .offer {
              padding-bottom: 40px;
            }
          }

        }
      }

    }
  }
}

/* Entertainment Calendar Template */
.entertainment-calendar-template {

  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }
  }

  .content {
    .content-holder {
      padding: 30px;
      h2 {
        display: none;
      }
      h3 {
        color: $colorFireBush;
        margin-left: 15px;
      }
      .shows, .events {
        .inner {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto 20px;

          .event {
            flex: 1 0 100%;
            margin-bottom: 30px;
            -webkit-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
            box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);

            @include media-breakpoint-up(sm) {
              flex: 0 0 46.9%;
              margin-bottom: 0px;
              margin: 10px;
            }

            @include media-breakpoint-up(md) {
              flex: 0 0 30%;
            }
            .event-thumbnail {
              background-color: black;
              height: 130px;
              background-position: center center;
              background-size: cover;
            }
            .event-content {
              padding: 30px;
              position: relative;
              font-size: 14px;
              h4 {
                color: $colorPuertoRico;
                font-size: 20px;
                text-align: center;
                min-height: 50px;
              }
              .schedule {
                font-size: 12px;
                text-align: center;
                margin-left: -30px;
                margin-bottom: 15px;
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;

                }
              }
              a.button-event {
                position: relative;
                z-index: 0;
                text-transform: uppercase;
                color: $colorPuertoRico;
                background-color: white;
                border: 1px solid $colorPuertoRico;
                letter-spacing: 2px;
                display: block;
                margin-bottom: 15px;
                &:before {
                  content: "";
                  position: absolute;
                  z-index:-1;
                  top: 0;  left: 0;  right: 0; bottom: 0;
                  transform: scaleX(0);
                  transform-origin: 0 50%;
                  transition: transform .3s ease-out;
                  background: $colorPuertoRico;
                }
                &:hover {
                  color: white;
                  &:before {
                    transform: scaleX(1);
                  }
                }
              }
              a.button-link {
                display: block;
                color: $colorPuertoRico;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .offers-holder {
      padding-bottom: 30px;
    }
  }
}

/* Event Detail Template */
.event-template {

  .container.content {
    position: relative;
    overflow: hidden;
    padding-top: 225px;

    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }
  }

  .container {
    position: relative;

    .content-holder {
      position: relative;
      z-index: 400;
      margin-top: -75px;
      margin-left: 0px;
      margin-right: 0px;

      .content-mask {
        position: absolute;
        top: 0;
        height: 100px;
        width: 100%;
        background-image: url(/images/content-swoop-mask.svg);
        background-repeat: no-repeat;
      }

      .content-column {
        padding-top: 120px;

        h1 {
          color: $colorFireBush;
          margin-bottom: 0px;
          font-size: 32px;
        }

        h2 {
          color: $colorPuertoRico;
          font-family: $fontMontserrat;
          font-size: 20px;
          font-weight: 100;
        }

        .btn {
          text-transform: uppercase;
          color: $colorPuertoRico;
          background-color: white;
          border: 1px solid $colorPuertoRico;
          letter-spacing: 2px;
          margin-bottom: 10px;
          position: relative;
          z-index: 0;
          &:before {
            content: "";
            position: absolute;
            z-index:-1;
            top: 0;  left: 0;  right: 0; bottom: 0;
            transform: scaleX(0);
            transform-origin: 0 50%;
            transition: transform .3s ease-out;
            background: $colorPuertoRico;
          }
          &:hover {
            color: white;
            &:before {
              transform: scaleX(1);
            }
          }
        }

      }

      .content-sidebar {
        padding-top: 30px;
        @include media-breakpoint-up(lg) {
          padding-top: 120px;
        }

        .gallery-holder {
          padding: 0px;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 20px;

          @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 30px;
            padding-bottom: 10px;
          }

          .slick-prev {
            left: -27px;
          }
          .slick-next {
            right: -27px;
          }
          .slick-dots {
            margin-top: 10px;
          }

          .gallery-inner {
            .gallery-image {
              .gallery-image-thumbnail {
                height: 200px;
                background-position: center center;
                background-size: cover;
                margin: 0px 10px;
                outline: 1px solid white !important;
                outline-offset: -10px;
              }
            }
          }
        }

        .offers-holder {
          padding: 0px;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 20px;

          .slick-prev {
            left: -27px;
          }
          .slick-next {
            right: -27px;
          }
          .slick-dots {
            margin-top: -50px;
          }

          @include media-breakpoint-up(md) {
          }

          @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 30px;
            padding-bottom: 10px;
          }

          .offers-inner {
            .offer {
              padding-bottom: 40px;
            }
          }

        }
      }

    }
  }
}

/* Common Sections */

.horizontal-orange {
  background: #e85434; /* Old browsers */
  background: -moz-linear-gradient(left,  #e85434 0%, #ec8e33 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #e85434 0%,#ec8e33 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #e85434 0%,#ec8e33 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e85434', endColorstr='#ec8e33',GradientType=1 ); /* IE6-9 */
}

.vertical-orange {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ec8d33+0,e85434+100 */
  background: #ec8d33; /* Old browsers */
  background: -moz-linear-gradient(top,  #ec8d33 0%, #e85434 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ec8d33 0%,#e85434 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ec8d33 0%,#e85434 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec8d33', endColorstr='#e85434',GradientType=0 ); /* IE6-9 */

}

.offers-holder {
  position: relative;

  text-align: center;

  padding-left: 30px;
  padding-right: 30px;

  h3 {
    color: white;
    line-height: 80px;
    margin: 0;
  }

  .offers-inner {

    .slick-arrow {
      position: absolute;
      top: 160px;
      width: 18px;
      height: 30px;
      line-height: 0;
      font-size: 0;
      background-color: transparent;
      background-image: url(/images/arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: 0px;
      cursor: pointer;
    }
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
      transform: scaleX(-1);
    }
    .slick-dots {
      margin-left: 9.5px;
      margin-right: 9.5px;
      padding: 0px;
      list-style: none;
      text-align: center;
      li {
        position: relative;
        display: inline-block;
        padding: 0;
        button {
          margin-left: 5px;
          margin-right: 5px;
          background-color: transparent;
          border: 0px;
          cursor: pointer;
          font-size: 0;
          padding: 0;
          width: 27px;
          height: 27px;
          background-image: url(/images/offer-dot.svg);
          background-repeat: no-repeat;
        }
        &.slick-active {
          button {
            background-image: url(/images/offer-dot-selected.svg);
          }
        }
      }
    }

  }

}

.offers-holder {
  &.offers-1 {
    .slick-arrow {
      display: none;
    }
    .slick-dots {
      display: none;
    }
  }
}

.offer {
  text-align: center;
  background-color: white;
  border: 10px solid white;
  margin: 0 10px;

  .offer-thumbnail {
    height: 130px;
    background-position: center center;
    background-size: cover;
  }

  .offer-content {
    padding: 20px;
    height: 220px;
    position: relative;
    font-size: 13px;

    h3 {
      color: $colorPuertoRico;
      font-size: 20px;
      line-height: 20px;
    }
    h4 {
      color: $colorPuertoRico;
      font-family: $fontMontserrat;
      font-size: 14px;
      font-weight: 100;
    }
    a.button-offer {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      text-transform: uppercase;
      color: $colorPuertoRico;
      background-color: white;
      border: 1px solid $colorPuertoRico;
      letter-spacing: 2px;
      display: block;
      z-index: 0;
      &:before {
        content: "";
        position: absolute;
        z-index:-1;
        top: 0;  left: 0;  right: 0; bottom: 0;
        background: $colorPuertoRico;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform .3s ease-out;

      }
      &:hover {
        color: white;
        &:before {
          transform: scaleX(1);
        }
      }
    }
  }

}

.gallery-1 {
  .slick-arrow {
    display: none;
  }
  .slick-dots {
    display: none;
  }
}

.gallery-holder {
  position: relative;

  text-align: center;

  padding-left: 30px;
  padding-right: 30px;

  h3 {
    color: white;
    line-height: 80px;
    margin: 0;
  }

  .gallery-inner {

    .slick-arrow {
      position: absolute;
      top: 85px;
      width: 18px;
      height: 30px;
      line-height: 0;
      font-size: 0;
      background-color: transparent;
      background-image: url(/images/arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: 0px;
      cursor: pointer;
    }
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
      transform: scaleX(-1);
    }
    .slick-dots {
      margin-left: 9.5px;
      margin-right: 9.5px;
      padding: 0px;
      list-style: none;
      text-align: center;
      li {
        position: relative;
        display: inline-block;
        padding: 0;
        button {
          margin-left: 5px;
          margin-right: 5px;
          background-color: transparent;
          border: 0px;
          cursor: pointer;
          font-size: 0;
          padding: 0;
          width: 27px;
          height: 27px;
          background-image: url(/images/gallery-dot.svg);
          background-repeat: no-repeat;
        }
        &.slick-active {
          button {
            background-image: url(/images/gallery-dot-selected.svg);
          }
        }
      }
    }

  }

}

.content-holder {
  .content-column {
    form {
      margin-bottom: 20px;

      button[type="submit"] {
        border: 1px solid $colorPuertoRico;
      }
    }
  }
}

.promotion-holder {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    text-align: left;
    padding: 20px 20px;
    align-items: center;
  }

  .promotion-thumb {
    order: 2;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      order: 1;
      margin-right: 20px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .promotion-content {
    order: 1;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      order: 2;
      padding-right: 250px;
    }
    h4 {
      color: $colorPuertoRico;
    }
    .btn {
      display: none;
      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
  }

  .extra-promotion-button {
    order: 3;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .btn {
    text-transform: uppercase;
    color: $colorPuertoRico;
    background-color: white;
    border: 1px solid $colorPuertoRico;
    letter-spacing: 2px;
    display: block;
    position: relative;
    z-index: 0;
    &:before {
      content: "";
      position: absolute;
      z-index:-1;
      top: 0;  left: 0;  right: 0; bottom: 0;
      background: $colorPuertoRico;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform .3s ease-out;

    }
    &:hover {
      color: white;
      &:before {
        transform: scaleX(1);
      }
    }
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

}

.cookie-accept, .browser-update {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 10px 35px 0px rgba(0,0,0,0.1);
  font-family: $fontMontserrat;
  font-weight: 100;
  font-size: 14px;
  z-index: 2000;
  .btn {
    background-color: $colorFireBush;
    color: white;
    text-transform: uppercase;
    margin-left: 10px;
    letter-spacing: 2px;
    position: relative;
    z-index: 0;
    &:before {
      content: "";
      position: absolute;
      z-index:-1;
      top: 0;  left: 0;  right: 0; bottom: 0;
      background: white;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform .3s ease-out;

    }
    &:hover {
      color: $colorFireBush;
      &:before {
        transform: scaleX(1);
      }
    }
  }
}
