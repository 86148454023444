.footer {
  position: relative;
  z-index: 100;
  background-color: $colorSilver;

  &:before {
    content: '';
    width: 100%;
    height: 5px;
    display: block;
    background-image: url(../../images/ruler-footer.svg);
    background-position: 0%;
  }

  h2 {
    font-size: 1.5rem;
    color: $colorFireBush;
    margin-bottom: 20px;
  }

  .container {
    padding: 50px;

  }

  .newsletter-form {
    input {
      color: $colorStone;
      border-bottom: 1px solid $colorStone;
    }
    button {
      border: 1px solid $colorPuertoRico;
    }
    #newsletterMessage {
      display: none;
    }
  }

  .links-and-social {
    position: relative;
    z-index: 100;
    display: block;
    padding: 40px 0px;

    @include media-breakpoint-up(md) {
      padding: 15px 0px;
    }

    a.link {
      display: block;
      text-align: center;
      padding: 15px;
      text-transform: uppercase;
      font-family: $fontMontserrat;
      font-size: 14px;
      color: #444444;
    }

  }

  .social-icons {
    display: inline-block;
    position: relative;
    z-index: 100;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    .socialIcon {
      display: inline-block;
      width: 35px;
      height: 35px;
      margin-right: 6px;
      margin-left: 6px;
      svg {
        fill: $colorPuertoRico;
        &:hover {
          fill: $colorFireBush;
        }
      }
    }
  }

  .location {
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }

    a.btn {
      position: relative;
      z-index:0;
      text-transform: uppercase;
      color: $colorPuertoRico;
      background-color: white;
      border: 1px solid $colorPuertoRico;
      letter-spacing: 2px;
      &:before {
        content: "";
        position: absolute;
        z-index:-1;
        top: 0;  left: 0;  right: 0; bottom: 0;
        background: $colorPuertoRico;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform .3s ease-out;

      }
      &:hover {
        color: white;
        &:before {
          transform: scaleX(1);
        }
      }
    }

  }

}
