.callout {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 10px solid #fff;
  text-align: center;

  &-holder {
    border: 10px solid #fff;
  }

  &:nth-child(odd){
    .callout-image {
      border-right: 10px solid white;
    }

  }

  &:nth-child(even){
    flex-direction: row-reverse;
    .callout-image {
      border-left: 10px solid white;
    }
  }

  &-image {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
      flex: 5;
      background-position: center center;
      background-size: cover;
      &:before {
        content: "";
        outline: 1px solid white !important;
        outline-offset: -10px;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-content {

    flex: 3;
    padding: 30px;
    color: white;
    font-size: 14px;

    h3 {
      margin: 0px;
      margin-bottom: 10px;
    }

    p {
      margin: 0px;
      margin-bottom: 10px;
    }

    .button-callout {
      position: relative;
      z-index: 500;
      border-width: 1px;
      border-style: solid;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 10px 20px;
      color: white;
      border-color: white;
      &:before {
        content: "";
        position: absolute;
        z-index:-1;
        top: 0;  left: 0;  right: 0; bottom: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform .3s ease-out;

      }
      &:hover {
        &:before {
          transform: scaleX(1);
        }
      }
    }

  }

  &:nth-child(4n + 1) {
    background-color: $colorSeashell;
    h3 {
      color: $colorFireBush;
    }
    .callout-content {
      color: black;
    }
    .button-callout {
      color: $colorPuertoRico;
      border-color: $colorPuertoRico;
      &:before {
        background: $colorPuertoRico;
      }
      &:hover {
        color: white;
      }
    }
  }

  &:nth-child(4n + 2) {
    background-color: $colorFireBush;
    color: white;
    .button-callout {
      &:before {
        background: white;
      }
      &:hover {
        color: $colorFireBush;
      }
    }
  }

  &:nth-child(4n + 3) {
    background-color: $colorCinnabar;
    .button-callout {
      &:before {
        background: white;
      }
      &:hover {
        color: $colorCinnabar;
      }
    }
  }

  &:nth-child(4n + 4) {
    background-color: $colorPuertoRico;
    .button-callout {
      &:before {
        background: white;
      }
      &:hover {
        color: $colorPuertoRico;
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }

}
