.hero {
  position: relative;
  overflow: hidden;
  z-index: 0;

  height: 250px;
  @include media-breakpoint-up(sm) {
    height: 300px;
  }
  @include media-breakpoint-up(md) {
    height: 350px;
  }
  @include media-breakpoint-up(lg) {
    height: 475px;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .content {
    z-index: 3;
    position: absolute;
    left: 45px;
    bottom: 25px;
    color: white;
    @include media-breakpoint-up(md) {
      left: 50px;
      bottom: 50px;
    }
    h1 {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      margin-right: 100px;
      @include media-breakpoint-up(md) {
        max-width: 300px;
      }
    }
    .hero-body {
      font-size: 23px;
      margin-bottom: 10px;
      p {
        margin-bottom: 0px;
      }
    }
    .hero-button {
      position: relative;
      z-index:0;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 10px 20px;
      border: 1px solid white;
      color: white;
      &:before {
        content: "";
        position: absolute;
        z-index:-1;
        top: 0;  left: 0;  right: 0; bottom: 0;
        background: white;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform .3s ease-out;

      }
      &:hover {
        color: $colorBright;
        &:before {
          transform: scaleX(1);
        }
      }
    }
  }

  &.masked {
    z-index: 500;
    mask: url(/images/content-swoop-mask.svg);
    -webkit-mask: url(/images/content-swoop-mask.svg) bottom left / cover;
    -o-mask: url(/images/content-swoop-mask.svg) bottom left / cover;
    -ms-mask: url(/images/content-swoop-mask.svg) bottom left / cover;
    -webkit-mask-repeat: no-repeat;

    .overlay {
      height: 250px;
      background-image: url(/images/content-swoop-lines.svg);
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: cover;
      margin-top: 5px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url(/images/hooters-white-wave.png);
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        margin-top: 1px;
      }

      @include media-breakpoint-up(sm) {
        height: 300px;
      }

      @include media-breakpoint-up(md) {
        height: 350px;
      }

      @include media-breakpoint-up(lg) {
        height: 475px;
      }

    }
  }

  &.type-homepage {
    position: relative;
    height: 460px;
    @include media-breakpoint-up(md) {
      height: 485px;
    }
    @include media-breakpoint-up(lg) {
      height: 594px;
    }
    .image {
      max-height: 80%;
      @include media-breakpoint-down(xs) {
        max-height: 65%;
      }
    }
    .overlay {
      width: 100%;
      height: 100%;
      background-position: 15% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      background-image: url(/images/home-hero-swoop.svg);
      background-size: 1380px 290px;
      @include media-breakpoint-up(md) {
        background-position: 15% 100%;
        background-size: 1380px 290px;
      }
      @include media-breakpoint-up(lg) {
        background-position: center bottom;
        background-size: 1380px 290px;
      }
    }
    .content {
      @include media-breakpoint-up(md) {
        h1 {
          font-size: 26px;
        }
        .hero-body {
          font-size: 18px;
          max-width: 300px;
        }
      }
      @include media-breakpoint-up(lg) {
        h1 {
          font-size: 30px;
        }
        .hero-body {
          font-size: 23px;
          max-width: 100%;
        }
      }
    }
  }

  &.type-content {
  }

  &.type-title {
  }

  &.type-details {
  }

}



#heros {
  .slick-arrow {
    position: absolute;
    bottom: 80px;
    width: 18px;
    height: 30px;
    line-height: 0;
    font-size: 0;
    background-color: transparent;
    background-image: url(/images/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0px;
    cursor: pointer;
    z-index: 501;
    opacity: 0;
    @include media-breakpoint-down(sm) {
      opacity: 1;
    }
  }
  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
    transform: scaleX(-1);
  }
  .slick-dots {
    margin-top: -40px;
    margin-bottom: 14px;
    margin-left: 65px;
    margin-right: 27px;
    padding: 0px;
    list-style: none;
    li {
      position: relative;
      display: inline-block;
      padding: 0;
      button {
        margin-left: 5px;
        margin-right: 5px;
        background-color: transparent;
        border: 0px;
        cursor: pointer;
        font-size: 0;
        padding: 0;
        width: 15px;
        height: 15px;
        background-image: url(/images/gallery-dot.svg);
        background-repeat: no-repeat;
      }
      &.slick-active {
        button {
          background-image: url(/images/gallery-dot-selected.svg);
        }
      }
    }
    @include media-breakpoint-down(sm) {
      opacity: 0;
    }
  }
}

